const singular = require('./src/singular')
const maxmind = require('./src/maxmind')

exports.onInitialClientRender = () => {
    singular()
    maxmind()
}

exports.onRouteUpdate = ({ location }) => {
    window.dispatchEvent(new Event('NAVIGATE'))

    const { hash } = location
    const el = hash ? document.querySelector(hash) : null
    if (el) window.requestAnimationFrame(() => window.scrollTo(0, el.offsetTop))
}

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition, }) => {
    const savedScrollPosition = getSavedScrollPosition(location)
    window.requestAnimationFrame(() => window.scrollTo(...savedScrollPosition))
    return false
}
