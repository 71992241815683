module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e205c998179f0f9369d8e19670b68a4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Oswald","file":"https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap"},{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;800&display=swap"},{"name":"Zen Tokyo Zoo","file":"https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo:wght@400&display=swap"},{"name":"Michroma","file":"https://fonts.googleapis.com/css2?family=Michroma&display=swap"},{"name":"Microgramma Extd D Bold","file":"https://use.typekit.net/mfv0aww.css"},{"name":"Shuttleblock Condensed","file":"https://use.typekit.net/rhp3skp.css"},{"name":"Changeling Neo","file":"https://use.typekit.net/wmi5bia.css"},{"name":"Audiowide","file":"https://fonts.googleapis.com/css2?family=Audiowide&display=swap"},{"name":"Sabon","file":"https://use.typekit.net/lza7jae.css"},{"name":"Eloquent JF","file":"https://use.typekit.net/chu4cyq.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5KRQQM6","enableWebVitalsTracking":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
