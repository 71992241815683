import { useState } from 'react'

export default function useGetAppValue() {
    const [country, setCountry] = useState(null)
    const [cartId, setCartId] = useState('')
    const [cart, setCart] = useState(null)
    const [showCart, setShowCart] = useState(false)
    const [loadingCart, setLoadingCart] = useState(false)
    const [showNav, setShowNav] = useState(false)
    const [screenWidth, setScreenWidth] = useState(typeof window !== `undefined` ? window.screen.width : null)
    const [clientWidth, setClientWidth] = useState(typeof document !== `undefined` ? document.body.clientWidth : null)
    const [searchClicked, setSearchClicked] = useState(false)
    const [singularDeviceId, setSingularDeviceId] = useState('')
    
    return {
        country, setCountry,
        cartId, setCartId,
        cart, setCart,
        showCart, setShowCart,
        loadingCart, setLoadingCart,
        showNav, setShowNav,
        screenWidth, setScreenWidth,
        clientWidth, setClientWidth,
        searchClicked, setSearchClicked,
        singularDeviceId, setSingularDeviceId,
    }    
}
