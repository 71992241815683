import React, { useRef } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ESW_CLASSNAMES from '../esw/classnames'
import RemoveFromCart from './remove-from-cart'
import PriceDisplay from './price-display'
import useGetAllProduct from '../hooks/useGetAllProduct'

import chatinfoicon from '../images/svgs/chat-box-info-icon-black.svg'
import cancelicon from '../images/svgs/cancel-icon-black.svg'

export default function CartItem({ item }) {
    const allProduct = useGetAllProduct()
    const rowRef = useRef(null)

    const { product_id, qty } = item
    const { node } = allProduct.find(({ node }) => node.productId === product_id)
    const { product_name, shop_categories, slug, childrenFile, prices } = node

    /**
     * when making changes to the cart item
     * there are two versions, large/small screen
     * (because they have major layout differences)
     * usally you have to make changes to both
     * (see the separate rows below)
     */
    return (
        <div ref={rowRef} className={`${ESW_CLASSNAMES.CART_ITEM} border-bottom border-dark pb-3 mb-3`}>
            {/* LARGE SCREEN */}
            <Row className='d-none d-md-flex align-items-center'>
                <Col xs='auto'>
                    <GatsbyImage
                        image={getImage(childrenFile[0])}
                        alt=''
                        style={{ width: 100 }}
                        objectFit='contain'
                    />
                </Col>
                <Col className='d-flex flex-column'>
                    <p className='ff-primary text-uppercase fw-bold mb-0' style={{ fontSize: 24 }}>
                        {product_name}
                    </p>
                    <p className='text-secondary text-uppercase fw-semibold mb-0'>
                        {shop_categories[0].name}
                    </p>
                </Col>
                {/* <Col>
                    <p className='d-flex align-items-center gap-1 mb-0' style={{ fontSize: 14 }}>
                        Digital Download
                        <img src={chatinfoicon} alt='' title='Digital Download will be emailed to you immediately after purchase and added to your online account' />
                    </p>
                </Col> */}
                <Col>
                    <PriceDisplay
                        item={item}
                        prices={prices}
                        quantity={qty}
                        style={{ fontSize: 18 }}
                        className='d-flex align-items-center gap-2 mb-0 fw-semibold'
                        eswPriceClassName={ESW_CLASSNAMES.CART_PRICE}
                        eswPriceStrikedClassname={ESW_CLASSNAMES.PAGE_PRICE}
                    />
                </Col>
                <Col xs='auto'>
                    <RemoveFromCart el={rowRef} product={node} className='flex-center'>
                        <img src={cancelicon} alt='' />
                    </RemoveFromCart>
                </Col>
            </Row>

            {/* SMALL SCREEN */}
            <Row xs={3} className='d-flex d-md-none align-items-center'>
                <Col xs='auto'>
                    <GatsbyImage
                        image={getImage(childrenFile[0])}
                        alt=''
                        style={{ width: 100 }}
                        objectFit='contain'
                    />
                </Col>
                <Col className='d-flex flex-column'>
                    <p className='ff-primary text-uppercase fw-bold mb-1' style={{ fontSize: 20 }}>
                        {product_name}
                    </p>
                    <p className='text-secondary text-uppercase fw-semibold mb-1' style={{fontSize: 12}}>
                        {shop_categories[0].name}
                    </p>
                    {/* <p className='d-flex align-items-center gap-1 mb-2' style={{ fontSize: 12 }}>
                        Digital Download
                        <img src={chatinfoicon} alt='' title='Digital Download will be emailed to you immediately after purchase and added to your online account' />
                    </p> */}
                    <PriceDisplay
                        item={item}
                        prices={prices}
                        quantity={qty}
                        style={{ fontSize: 16 }}
                        className='d-flex align-items-center gap-2 mb-0 fw-semibold'
                        eswPriceClassName={ESW_CLASSNAMES.CART_PRICE}
                        eswPriceStrikedClassname={ESW_CLASSNAMES.PAGE_PRICE}
                    />
                </Col>
                <Col xs='auto' style={{marginLeft: 'auto'}}>
                    <RemoveFromCart el={rowRef} product={node} className='flex-center'>
                        <img src={cancelicon} alt='' />
                    </RemoveFromCart>
                </Col>
            </Row>
        </div>
    )
}